<script>
import PageTitle from "@/components/PageTitle.vue";
import JobInfoChip from "@/components/Chips/JobInfoChip.vue";
import {get} from "@/client/utilities/Object";
import ConfirmDialog from "@/components/V2/ConfirmDialog.vue";

export default {
    name: 'ListDevices',
    title: 'Devices',
    components: {ConfirmDialog, JobInfoChip, PageTitle},
    data() {
        return {
            devices: []
        }
    },
    methods: {
        get,
        async fetchDevices() {
            return this.$http.get('/v3/devices').then(response => {
                this.devices = response.data.devices;
            });
        },
        edit(device) {
            this.$router.push(`/tools/devices/${device.id}`);
        },
        remove(device) {
            this.$http.delete(`/v3/tools/${device.id}`).then(response => {
                this.fetchDevices();
            });
        }
    },
    async created() {
        await this.fetchDevices();
    }
}
</script>

<template>
    <v-container fluid>
        <PageTitle>
            <template v-slot:button>
                <v-btn
                    small
                    color="primary"
                    to="/tools/devices/create"
                >
                    Add Device
                </v-btn>
            </template>
        </PageTitle>
        <v-simple-table>
            <thead>
            <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Model</th>
                <th>Location</th>
                <th>IEMI</th>
                <th>Serial #</th>
                <th>Type</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(device, index) in devices" :key="device.id">
                <td>{{ device.id }}</td>
                <td>{{ device.name }}</td>
                <td>{{ device.sku }}</td>
                <td>
                    <JobInfoChip :key="`job-if-${index}`"
                                 v-if="device.job"
                                 :company-slug="get(device, 'job.company.slug')"
                                 :job-number="get(device, 'job.job_number')"
                    />
                    <JobInfoChip
                        v-else-if="device.vehicle"
                        company-slug="Vehicle"
                        :job-number="get(device,'vehicle.name')"
                    />
                    <JobInfoChip
                        v-else-if="device.employee"
                        company-slug="Employee"
                        :job-number="get(device, 'employee.name')"
                    />
                    <span :key="`job-else-${index}`" v-else>
                            {{ device.tool_location_key }}
                        </span>
                </td>
                <td>{{ device.imei }}</td>
                <td>{{ device.serial_number }}</td>
                <td>{{ device.tool_type_display_name }}</td>
                <td>
                    <v-btn
                        color="primary"
                        small
                        @click="edit(device)"
                        class="mr-3 my-2"
                    >
                        <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                    <confirm-dialog color="delete"
                                    small
                                    :message="`Are you sure you want to delete device id ${device.id}?`"
                                    @confirm="remove(device)"
                    >
                        <v-icon small>mdi-delete</v-icon>
                    </confirm-dialog>
                    <div class="mt-2"></div>
                </td>
            </tr>
            </tbody>
        </v-simple-table>
    </v-container>
</template>

<style scoped>

</style>